<template>
	<div>
		<div v-if="!card_loaded">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else class="row justify-content-center">
			<div class="col-auto pb-5" v-for="card in cards" :key="card.card_id">
				<b-card class="BoardingCards" no-body>
					<div class="text-center card-img"><img :src="require('@/assets/img/cards/'+ card.cards_code +'.png')" alt=""></div>
					<b-card-body>
						<h3>{{ card.cards_label }}</h3>
						<div v-if="card.cards_code == 'principal_activity'">
							<b-card-text>{{ $t('cards.question_activity') }}</b-card-text>
						</div>
						<div v-if="card.cards_code == 'signature_electronique'">
							<b-card-text>{{ $t('cards.signature_prix') }}</b-card-text>
						</div>
						<!-- <div>
							<b-card-text>{{ card.cards_text }}</b-card-text>
						</div> -->
						<div v-if="card.cards_code == 'trainer_code'">
							<b-input-group>
								<b-form-input class="form-control" :placeholder="$t('cards.question_trainer_code')" type="text" v-model="letrot_keyword"/>
								<b-input-group-append v-if="letrot_keyword.length > 1">
									<button class="btn btn-primary" type="button" @click.prevent="getResultsLeTrot"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t('global.valider') }}</button>
								</b-input-group-append>
							</b-input-group>
							<div v-if="results.length > 0">
								<div v-for="result in results" :key="result.code" @click.prevent="saveCode(result)">
									- {{ result.label }}
								</div>
							</div>
						</div>
						<div v-if="card.cards_code == 'pappers'">
							<b-card-text>
								{{ $t('cards.pappers_desc1') }} <a href="https://www.pappers.fr/" target="blank">https://www.pappers.fr/</a> {{ $t('cards.pappers_desc2') }}
							</b-card-text>
							<b-input-group>
								<b-form-input class="form-control" :placeholder="$t('cards.pappers_placeholder')" type="text" v-model="pappers_key"/>
								<b-input-group-append v-if="pappers_key.length > 0">
									<button class="btn btn-primary" type="button" @click.prevent="savePappers"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t('global.valider') }}</button>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-card-body>
					<b-card-footer>
						<div v-if="card.cards_code == 'principal_activity'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="enableActTypesTrot">{{ $t('cards.trotter') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-primary rounded-pill" @click="enableActTypesGalop">{{ $t('cards.galloper') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-primary rounded-pill" @click="enableActTypesSport">{{ $t('cards.horse_sport') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('cards.skip') }}</div></div>
						</div>
						<div v-else-if="card.cards_code == 'horse_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code + '_one', card.cards_video)"><font-awesome-icon :icon="['far', 'plus']" /> {{ $t('cards.first_horse') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code + '_lot', card.cards_video)"><font-awesome-icon :icon="['far', 'upload']" /> {{ $t('cards.bulk_import') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('cards.skip') }}</div></div>
							<div v-if="card.cards_video" class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://player.vimeo.com/video/' + video_links[card.cards_code]" target="_blank">
										<font-awesome-icon :icon="['fas', 'eye']" /> {{ $t('cards.show_me') }}
									</a>
								</div>
							</div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'registre_transport'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'link_acts_articles'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'registre_elevage'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'bien_etre_animal'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'pension_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'article_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'accountingplan_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'tiers_manage'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://equideclic.atlassian.net/servicedesk/customer/article/' + doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'bank_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'accountingaccount_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'tiers_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'mvt_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'act_create'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'horse_create_tiers'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'plus']" /> {{ $t('cards.first_horse') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('cards.skip') }}</div></div>
							<div v-if="card.cards_video" class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://player.vimeo.com/video/' + video_links[card.cards_code]" target="_blank">
										<font-awesome-icon :icon="['fas', 'eye']" /> {{ $t('cards.show_me') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'trainer_code'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('cards.skip') }}</div></div>
						</div>
						<div v-else-if="card.cards_code == 'horses_letrot'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="addHorsesFromLeTrot(card.cards_code)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
						</div>
						<div v-else-if="card.cards_code == 'pappers'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="doc_links[card.cards_code]" target="_blank">
										{{ $t('cards.learn_more') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else-if="card.cards_code == 'signature_electronique'" class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="$t('global.confluence_signature_elec')" target="_blank">
										<font-awesome-icon :icon="['fas', 'eye']" /> {{ $t('cards.show_me') }}
									</a>
								</div>
							</div>
						</div>
						<div v-else class="row no-gutters justify-content-center">
							<div class="col-auto mt-2"><div class="btn btn-primary rounded-pill" @click="clickYes(card.cards_code, card.cards_video)"><font-awesome-icon :icon="['far', 'check']" /> {{ $t('global.oui') }}</div></div>
							<div class="col-auto mt-2 ml-3"><div class="btn btn-no rounded-pill" @click="clickNo(card.cards_code)"><font-awesome-icon :icon="['far', 'times']" /> {{ $t('global.non') }}</div></div>
							<div v-if="card.cards_video" class="col-auto mt-2 ml-3">
								<div class="btn btn-no rounded-pill">
									<a :href="'https://player.vimeo.com/video/' + video_links[card.cards_code]" target="_blank">
										<font-awesome-icon :icon="['fas', 'eye']" /> {{ $t('cards.show_me') }}
									</a>
								</div>
							</div>
						</div>
					</b-card-footer>
				</b-card>
			</div>
		</div>

		<ModalConfirm
			ref="disable_act_type"
			id="disable_act_type"
			:icon="['far', 'save']"
			:icon_accept="['fal', 'level-down']"
			:text_title="$t('cards.modal.enable_act_types')"
			:text_question="text_question"
			:text_button_ok="$t('global.oui')"
			:text_button_accept="$t('modal.general.ok')"
			:text_button_cancel="$t('modal.general.annuler')"
			:callback_validation="confirmDisableActTypeModal"
			:callback_cancel="cancelDisableActTypeModal"
			:processing="processing_modal"
		/>

		<ModalCardsVideo ref="modal_cards_video" />
	</div>
</template>

<script type="text/javascript">
import Accounting from '@/mixins/Accounting.js'
import ActeType from '@/mixins/ActeType.js'
import Cards from '@/mixins/Cards.js'
import Horse from '@/mixins/Horse.js'
import Shutter from "@/mixins/Shutter.js"
import ShutterCards from '@/mixins/shutters-manager/Cards.js'
import Tiers from '@/mixins/Tiers.js'
import User from "@/mixins/User.js"

export default {
	name: "cardsDashboard",
	mixins: [Accounting, ActeType, Cards, Horse, Shutter, ShutterCards, Tiers, User],
	data () {
		return {
			cards: [],
			text_question: '',
			principal_activity: '',
			processing_modal: false,
			card_loaded: false,
			letrot_keyword: '',
			processing: false,
			results: [],
			pappers_key: '',
			card_link: '',
			video_links: {
				// registre_elevage: '',
				// bien_etre_animal: '',
                // registre_transport: '',
				// principal_activity: '',
				horse_create: '464934239',
				horse_create_tiers: '464934239',
                act_create: '464949475',
                mvt_create: '464945393',
                tiers_create: '466487079',
                tiers_manage: '466491741',
                pension_create: '466498844',
                // accountingplan_create: '',
                accountingaccount_create: '466482796',
                // article_create: '',
                // bank_create: '',
                // link_acts_articles: ''
            },
			doc_links: {
				link_acts_articles: '5800362',
				registre_transport: '592937001',
				registre_elevage: '400916767',
				bien_etre_animal: '398655793 ',
				pappers: 'https://equideclic.atlassian.net/wiki/spaces/GROOMY1/pages/656703513/Param+trages+Avanc+s?parentProduct=JSM-Portal&parentProductContentContainerId=10004&initialAllowedFeatures=disable-login-flow.disable-share&locale=fr-FR#b.-Pappers',
                pension_create: '5734808',
                article_create: '5800362',
                accountingplan_create: '5800122',
                bank_create: 'https://equideclic.atlassian.net/wiki/spaces/GROOMY1/pages/5800142/Cr+er+une+remise+de+paiement?parentProduct=JSM-Portal&parentProductContentContainerId=10004&initialAllowedFeatures=disable-login-flow.disable-share&locale=fr-FR#A.-Cr%C3%A9er-les-comptes-bancaires',
                accountingaccount_create: '5767330',
                mvt_create: '5898507',
                tiers_create: '5800162',
                act_create: '5898552',
				horse_create: '5734743',
                tiers_manage: '5767440',
				// principal_activity: '',
				// horse_create_tiers: '464934239',
			},
			name_route: {
				horse_create_one: 'SearchSire',
                horse_create_lot: 'LotSire',
				horse_create_tiers: 'SearchSire',
                act_create: 'planning',
                mvt_create: 'mouvementListe',
                tiers_create: 'tiersListe',
                tiers_manage: 'tiersHorse',
                pension_create: 'AccountingFichePension',
                accountingaccount_create: 'AccountingFicheAccountingAccount'
            }
		}
	},
	created() {
		this.init_component(true)
	},
	methods: {
		async init_component(checking = false) {
			this.card_loaded = false
			if(checking) {
				await this.checkCards()
			}

			const cards = await this.getCards()
			this.cards = this.formatCards(cards)

			if(this.cards.length == 0 && this.$route.name == 'cards') {
				this.infoToast('toast.no_cards')
				this.$router.push({ name: 'horseListe' })
			}
			else {
				this.card_loaded = true
			}
		},
		formatCards(cards) {
			if(!cards) {
				return []
			}

			for (let i = 0; i < cards.length; i++) {
				cards[i].cards_label = this.getTrad('cards.' + cards[i].cards_code)
				// cards[i].cards_text = this.getTrad('cards.text.' + cards[i].cards_code)
			}

			return cards
		},
		async clickYes(code, video) {
			if(video) {
				const compta = ['pension_create', 'accountingaccount_create']
				if(compta.includes(code)) {
					const first_accountingplan = await this.loadFirstAccountingPlan()
					this.$router.push({ name: this.name_route[code], params: { accountingplan_id: first_accountingplan.accountingplan_id }})
				}
				else if(code == 'tiers_manage') {
					const first_tiers = await this.loadFirstTiers()
					this.$router.push({ name: this.name_route[code], params: { tiers_id: first_tiers.tiers_id }})
				}
				else if(code == 'horse_create_tiers') {
					this.$router.push({ name: this.name_route[code], params: { salon: true }})
				}
				else {
					this.$router.push({ name: this.name_route[code] })
				}
			}
			else {
				if(code == 'sync_perfs' || code == 'signature_electronique') {
					const params = {
						code: code,
						done: 1,
						skip: 0
					}
					this.submitCard(params)
				}
				else {
					this.setupTestShutter(code, this.submitCard)
				}
			}
		},
		clickNo(code) {
			if(code == 'registre_elevage') {
				this.setConfig('skip_residence_onboarding', 1)
			}

			const params = {
				code: code,
				done: 0,
				skip: 1
			}

			this.submitCard(params)
		},
		async submitCard(params) {
			if(params.code == 'trainer_code' && params.skip == 1) {
				const codes = ['trainer_code', 'last_perfs', 'next_perfs', 'synchro_perfs']
				codes.forEach(code => {
					let card = this.cards.filter(card => card.cards_code == code)
					if(card && card.length == 1) {
						card = card[0]

						this.saveCard(card.cards_id, params.done, params.skip)
						.then(() => {
							this.init_component()
						})
					}
				})
			}
			else {
				if(params.code == 'sync_perfs') {
					let card = this.cards.filter(card => card.cards_code == params.code)
					if(card && card.length == 1) {
						card = card[0]

						this.saveCard(card.cards_id, params.done, params.skip)
						.then(() => {
							this.syncPerformances()
						})
						.then(() => {
							this.init_component()
						})
					}
				}
				else {
					let card = this.cards.filter(card => card.cards_code == params.code)
					if(card && card.length == 1) {
						card = card[0]

						this.saveCard(card.cards_id, params.done, params.skip)
						.then(() => {
							this.init_component()
						})
					}
				}
			}
		},
		enableActTypesTrot() {
			this.text_question = this.getTrad('cards.activate_trotter')
			this.principal_activity = 1
			this.$refs.disable_act_type.openModal()
		},
		enableActTypesGalop() {
			this.text_question = this.getTrad('cards.activate_galloper')
			this.principal_activity = 2
			this.$refs.disable_act_type.openModal()
		},
		enableActTypesSport() {
			this.text_question = this.getTrad('cards.activate_sport')
			this.principal_activity = 3
			this.$refs.disable_act_type.openModal()
		},
		cancelDisableActTypeModal() {
			this.$refs.disable_act_type.closeModal()
		},
		confirmDisableActTypeModal() {
			this.processing_modal = true
			this.disableActType(this.principal_activity)
			.then(() => {
				const params = {
					code: 'principal_activity',
					done: 1,
					skip: 0
				}

				this.submitCard(params)
			})
			.then(() => {
				this.processing_modal = false
				this.$refs.disable_act_type.closeModal()
			})
		},
		async getResultsLeTrot() {
			this.processing = true
			await this.getLeTrotResults(this.letrot_keyword)
				.then(res => {
					this.results = res
					this.processing = false
				})
		},
		async saveCode(result) {
			const ap = await this.loadFirstAccountingPlan()
			if(ap) {
				this.saveTrainerCode(ap.accountingplan_id, result.code)
					.then(() => {
						const params = {
							code: 'trainer_code',
							done: 1,
							skip: 0
						}
						this.submitCard(params)
					})
			}
			else {
				this.failureToast('toast.no_compta')
			}
		},
		async addHorsesFromLeTrot(code) {
			await this.addLeTrotHorses()
				.then(() => {
					const params = {
						code: code,
						done: 1,
						skip: 0
					}
					this.submitCard(params)
					this.infoToast('toast.long_process')
				})
		},
		async savePappers() {
			this.processing = true

			const pappers = [{
				type: 'PAP',
				value: this.pappers_key
			}]

            await this.saveLicenceApplication(pappers)
				.then(async () => {
					this.all_appli = await this.getAllApplications()
					const appli = await this.getLicenceApplications()
					this.setConfig('api_config', appli)
					this.processing = false

					const params = {
						code: 'pappers',
						done: 1,
						skip: 0
					}
					this.submitCard(params)
				})
		},
		openVideo(code) {
			this.card_link = "https://player.vimeo.com/video/" + this.video_links[code]
			this.$refs.modal_cards_video.openModal(this.card_link)
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		ModalCardsVideo: () => import('@/components/Modals/ModalCardsVideo')
	}
}
</script>
